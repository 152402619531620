import { DOMAIN_TO_STAGE, STAGE } from 'src/constants/StageConfig';
import { EXTERNAL_URLS, ExternalURLKey, PAGE_PATHS } from 'src/constants/Urls';

/**
 * Method to get the current stage
 */
export const getCurrentStage = (): STAGE => {
  if (DOMAIN_TO_STAGE[window.location.host]) {
    return DOMAIN_TO_STAGE[window.location.host];
  }
  if (process.env.NODE_ENV !== 'production') {
    return STAGE.ALPHA;
  }
  return DOMAIN_TO_STAGE[window.location.host];
};

/**
 * Method to return if the current stage is prod or not
 * @returns {boolean} is current stage prod or not
 */
export const isCurrentStageProd = (): boolean => {
  return getCurrentStage() === STAGE.PROD || getCurrentStage() === STAGE.ONEBOX;
};

/**
 * Method to get external URLs for current stage
 */
export const getStageExternalURLs = () => {
  return EXTERNAL_URLS[getCurrentStage()];
};

/**
 * Method to get external URL for current stage
 */
export const getStageExternalURL = (externalUrlKey: ExternalURLKey): string => {
  return getStageExternalURLs()[externalUrlKey as string];
};

export const getTCUIDeepLink = (employeeId: string): string => {
  if (getCurrentStage() === STAGE.PROD || getCurrentStage() === STAGE.ONEBOX) {
    return EXTERNAL_URLS[STAGE.PROD].TCUI_PORTAL + employeeId;
  }
  return EXTERNAL_URLS[getCurrentStage()].TCUI_PORTAL + employeeId + PAGE_PATHS.TCUI_SPOOFED_USER;
};

/**
 * Method to get DALI Case URL for current stage
 */
export const getDaliCaseLink = (caseId: string) => {
  return `${getStageExternalURLs().DALI_LINK_PREFIX}Case/${caseId}/view`;
};

/**
 * Method to get the current migration status.
 */
export const getHomeBrewMigrationStatus = (applicationConfigContext?: any): boolean => {
  if (applicationConfigContext === undefined) {
    /**
     * applicationConfigContext === undefined means function called in *.ts file.
     * not context available in the file
     */
    return localStorage.getItem('homeBrewMigration') === 'true' ?? false;
  }
  const homeBrewMigrationFlag = applicationConfigContext?.config?.allConfig?.homeBrewMigration === 'true' ?? false;
  return homeBrewMigrationFlag;
};
