export const INTERNAL_SERVICE_EXCEPTION = 'INTERNAL_SERVICE_EXCEPTION';
export const RETRYABLE_EXCEPTION = 'RETRYABLE_EXCEPTION';

export const TAAPI_THROTTLED_REQUEST_ERROR_MESSAGE_SUBSTRING = 'Status Code: 429';

enum ERROR_CODE {
  SITE_FAILURE = 'site-failure',
  WORKFLOW_FAILURE = 'workflow-failure',
  WORKFLOW_TIMEOUT = 'workflow-timeout',
  EMPLOYEE_NOT_FOUND = 'employee-not-found',
  PXT_ONE_CASE_ACCESS_ERROR = 'pxt-one-case-access-error',
  ATP_CASE_ACCESS_ERROR = 'any-time-pay-access-error',
}

export enum SERVICE_ERROR_CODE {
  CREATE_CASE_INTERNAL_ERROR = 'CREATE_CASE_INTERNAL_ERROR',
  GET_CASES_INTERNAL_ERROR = 'GET_CASES_INTERNAL_ERROR',
  GET_TASKS_INTERNAL_ERROR = 'GET_TASKS_INTERNAL_ERROR',
  GET_CASE_INTERNAL_ERROR = 'GET_CASE_INTERNAL_ERROR',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_COMMENTS = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_COMMENTS',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_ACCOMMODATION_DETAILS = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_ACCOMMODATION_DETAILS',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_DOCUMENTS = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_DOCUMENTS',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_NOTES = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_NOTES',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_WATCHERS = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_WATCHERS',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_OTHER_SYSTEM_CASES = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_OTHER_SYSTEM_CASES',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_TASKS = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_TASKS',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_CASE_HISTORY = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_CASE_HISTORY',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_PREFERRED_QUEUES = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_PREFERRED_QUEUES',
  GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_CONTACT_DRIVERS = 'GET_CASE_ADDITIONAL_DETAILS_INTERNAL_ERROR_CONTACT_DRIVERS',
  GET_CASES_ADDITIONAL_DETAILS_INTERNAL_ERROR = 'GET_CASES_ADDITIONAL_DETAILS_INTERNAL_ERROR',
  GET_QUEUES_INTERNAL_ERROR = 'GET_QUEUES_INTERNAL_ERROR',
  GET_SHIFTS_INTERNAL_ERROR = 'GET_SHIFTS_INTERNAL_ERROR',
  GET_PUNCHES_INTERNAL_ERROR = 'GET_PUNCHES_INTERNAL_ERROR',
  GET_PAYCODES_INTERNAL_ERROR = 'GET_PAYCODES_INTERNAL_ERROR',
  GET_BALANCES_INTERNAL_ERROR = 'GET_BALANCES_INTERNAL_ERROR',
  PAPI_EMPLOYEE_NOT_FOUND_ERROR = 'PAPI_EMPLOYEE_NOT_FOUND_ERROR',
  SEARCH_EMPLOYEE_INTERNAL_ERROR = 'SEARCH_EMPLOYEE_INTERNAL_ERROR',
  INTERNAL_SERVER_ERROR_IS_EMPLOYEE_SUPPORTED = 'INTERNAL_SERVER_ERROR_IS_EMPLOYEE_SUPPORTED',
  TAAPI_INVALID_REQUEST_ERROR = 'TAAPI_INVALID_REQUEST_ERROR',
  TAAPI_THROTTLED_REQUEST = 'THROTTLED_REQUEST',
  CATALYST_FILE_DOES_NOT_EXIST = 'Catalyst_InvalidFileAttachment_FileDoesntExists',
  DEFAULT_ERROR = 'DEFAULT_ERROR',
  MYHR_GATEWAY_INTERNAL_SERVER_ERROR = 'MYHR_GATEWAY_INTERNAL_SERVER_ERROR',
}

export default ERROR_CODE;
